  
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Head from "next/head";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
//STYLES
import theme from "../theme";
import '../styles/globals.css'

export default function MyApp(props) {
  
  const { Component, pageProps } = props;

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no"/> 
        <link rel="manifest" href="/manifest.json" /> 
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" /> 
        <meta name='description' content='BLOG DE CIDU S.A.S. Dedicado a enseñar, explicar y dar a entender las distintas metodologías para el desarrollo de Avalúos Comerciales e Inmobiliarios.' />
        <link href="/images/circle16.png" rel="icon" type="image/png" sizes="16x16"/>
        <link href="/images/circle32.png" rel="icon" type="image/png" sizes="32x32"/>
        <link rel="apple-touch-icon" href="/images/apple-touch-icon.png"/><meta name="theme-color" content="#33658a" />
      </Head>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...pageProps} />
      </ThemeProvider>
    </>
  )
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};


