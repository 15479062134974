import { createMuiTheme } from "@material-ui/core/styles";

const mainBlack = "#212121";
const mainWhite = "#fafafa";
const blue = "#33658a";
const background = "#28282a"

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    common: {
      black: mainBlack,
      white: mainWhite,
      blue: blue,
    },
    primary: {
      main: mainBlack,
      background: background,
    },
    secondary: {
      main: mainWhite,
    },
    info: {
      main: "#86bbd8",
      blue: blue,
    },
  },
  typography: {
    h1: {
      fontSize: "2.5rem",
      fontWeight: 500,
      fontFamily: "Century-Gothic",
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: 500,
      fontFamily: "Century-Gothic",
    },
    h3: {
      fontSize: "1.25rem",
      fontWeight: 500,
      fontFamily: "Century-Gothic",
    },
    h4: {
      fontFamily: "Century-Gothic",
    },
    h5: {
      fontFamily: "Century-Gothic",
    },
    a: {
      color: mainBlack,
      fontFamily: "Century-Gothic",
    },
    P: {
      fontFamily: "Century-Gothic",
    },
  },
});

export default theme;